<template>
  <div>
    <div class="box">
      <p class="title is-6 has-text-dark">
        <span class="icon">
          <i class="fad fa-tasks"></i>
        </span>
        <span>
          Required fields
        </span>
      </p>
      <p class="subtitle is-6 has-text-grey-dark">
        Preparing your sheet to run smoothly
      </p>
      <div v-for="(field, index) in requiredFields" :key="index">
        <hr class="hr is-marginless" />
        <div class="columns is-vcentered is-marginless">
          <div class="column">{{ field.property }}</div>
          <div class="column is-narrow">
            <div class="tag is-light is-info is-small">
              {{ field.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <p class="title is-6 has-text-dark">
        <span class="icon">
          <i class="fad fa-fw fa-lightbulb-dollar"></i>
        </span>
        <span>
          Valuation parameters
        </span>
      </p>
      <p class="subtitle is-6 has-text-grey-dark">
        Additional parameters to adjust our values
      </p>
      <div v-for="(field, index) in additionalParams" :key="index">
        <hr class="hr is-marginless" />
        <div class="columns is-vcentered is-marginless">
          <div class="column">{{ field.property }}</div>
          <div class="column is-narrow">
            <div class="tag is-light is-warning is-small">
              {{ field.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="has-text-right">
      <a class="has-text-info" @click="toggleModal">
        View all
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'MultiFieldInfo',
  methods: {
    toggleModal() {
      this.$modal.open('multi/MultiFields')
    }
  },
  computed: {
    requiredFields() {
      return [
        {
          property: 'VRM',
          description: "The vehicle's registration number"
        },
        {
          property: 'VIN',
          description: "The vehicle's identification number"
        },
        {
          property: 'Retail',
          description: 'Produces the current retail price'
        },
        {
          property: 'Trade',
          description: 'Produces the current trade price'
        }
      ]
    },
    additionalParams() {
      return [
        {
          property: 'Date',
          description: 'Historic or future date [dd/mm/yyyy]'
        },
        {
          property: 'Mileage',
          description: 'Custom mileage input'
        }
      ]
    }
  }
}
</script>
